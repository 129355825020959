export const taskDashboardHighlights = [
  {
    name: "Search",
    markdown: `Use the search bar at the top to quickly find tasks by name, description, or linked content.`,
  },
  {
    name: "Create",
    markdown: `Use the {% inlineAppIcon iconName="plusCircle" /%}**New Task button** to {% inlineRouterLink articleId="create-a-task" %}create a new task{% /inlineRouterLink %}.`,
  },
  {
    name: "View by theme",
    markdown: `Themed views present tasks in ways that make it easy for you to figure out what to work on next. {% inlineRouterLink %}Today{% /inlineRouterLink %} and {% inlineRouterLink %}Scheduled{% /inlineRouterLink %} views provide chronological focus. {% inlineRouterLink articleId="find-nearby-tasks" %}Nearby{% /inlineRouterLink %} view provides geographic focus for tasks linked to datapoints. {% inlineRouterLink %}All{% /inlineRouterLink %} view shows you all of your lists at once. {% inlineRouterLink %}Completed{% /inlineRouterLink %} view makes it easy to see what you've gotten done.
    `,
  },
  {
    name: "View by list",
    markdown: `Every task belongs to a list. You can create as many lists as you'd like. A list can also be shared with {% inlineAppIcon iconName="user" /%}**users** and {% inlineAppIcon iconName="userGroup" /%}{% inlineRouterLink articleId="getting-started-for-team-leaders" %}**teams**{% /inlineRouterLink %}.
    `,
  },
];

export const tasksQuickStart = {
  order: 0,
  name: "Stay organized with tasks",
  keywords: "reminder search",
  subcategory: "Tasks",
  markdown: `# Stay organized with tasks

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**
  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink %}**Users with available credits**{% /inlineRouterLink %} on {% inlineAppIcon iconName="mobile" /%}{% inlineRouterLink %}**mobile**{% /inlineRouterLink %} and {% inlineAppIcon iconName="desktop" /%}{% inlineRouterLink %}**desktop**{% /inlineRouterLink %} devices.
  {% /tablessContainer %}

  Use {% inlineAppIcon iconName="star" /%} tasks to personalize your Tower Hunt experience and get more done. You can use tasks alone or {% inlineRouterLink articleId="share-tasks" %}share them with other users and teams{% /inlineRouterLink %}. Tasks can be {% inlineRouterLink articleId="edit-tasks" sectionName="Detailed editing" %}prioritized{% /inlineRouterLink %} and/or {% inlineRouterLink articleId="edit-tasks" sectionName="Detailed editing" %}set to trigger notifications{% /inlineRouterLink %}. Tasks can also be {% inlineRouterLink articleId="edit-tasks" sectionName="Datapoint linking" %}linked to datapoints{% /inlineRouterLink %}, which allows them to be displayed on {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}. If you've ever made a to-do list, you'll have no trouble getting started with tasks in Tower Hunt.

  ## What to expect

  Tasks are a {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go{% /inlineRouterLink %} feature in Tower Hunt. Accessing a task costs **$0.005** with a {% inlineRouterLink %}5-minute buffer{% /inlineRouterLink %}.

  ## Tasks dashboard

  Working with tasks usually starts from the dashboard located in the {% inlineAppIcon iconName="star" /%}**Tasks** section of the {% inlineRouterLink %}Me tab{% /inlineRouterLink %}. The tasks dashboard helps you quickly find, create, and view collections of tasks:

  ![Tasks dashboard screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$taskDashboardHighlights /%}
  `,
};
